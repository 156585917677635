import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { map, exhaustMap, catchError, finalize } from 'rxjs/operators';
import { ServicesService } from '../../_services/services.service';
import { ServicesActions } from '../_actions/services.actions';
import { LayoutActions } from '../_actions/layout.actions';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { AppState } from '../index';
import {
  LayoutUtilsServices,
  MessageType,
} from '../../_services/layout-utils.services';
import { currentServicesFilters } from '../_selectors/services.selectors';
import { RolesService } from '../../_services/roles.service';
import { ValuesService } from '../../_services/values.service';

@Injectable()
export class ServicesEffects {
  loadServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ServicesActions.loadServices),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._servicesService.findAll(action.filters).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((res) =>
            ServicesActions.loadServicesSuccess({
              items: res.data,
              total: res.total,
            })
          ),
          catchError(() => EMPTY)
        );
      })
    )
  );

  loadService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ServicesActions.loadService),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._servicesService.findOne(action.id).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((data) => ServicesActions.loadServiceSuccess({ data })),
          catchError(() => EMPTY)
        );
      })
    )
  );

  addService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ServicesActions.addService),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._servicesService.create(action.data).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((res) => {
            this._layoutUtilsServices.showNotification({
              message: 'Se ha creado el registro exitosamente',
              type: MessageType.Susses,
            });
            if (action.data.dealer_id) {
              this.router.navigate(['/dealers', action.data.dealer_id, 'edit']);
              return ServicesActions.loadService({ id: res.id });
            } else {
              this.router.navigate(['/services']);
              return ServicesActions.addServiceSuccess({ data: res });
            }


          }),
          catchError(({ error }) => {
            this._layoutUtilsServices.showNotification({
              message:
                error?.message ||
                'Error al crear el registro, por favor intente de nuevo',
              type: MessageType.Danger,
            });
            return EMPTY;
          })
        );
      })
    )
  );

  editService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ServicesActions.editService),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._servicesService.update(action.id, action.data).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((res) => {
            this._layoutUtilsServices.showNotification({
              message: 'Se ha actualizado el registro exitosamente',
              type: MessageType.Susses,
            });
            this.router.navigate(['/services']);
            return ServicesActions.editServiceSuccess({
              id: action.id,
              data: res,
            });
          }),
          catchError(({ error }) => {
            this._layoutUtilsServices.showNotification({
              message:
                error?.message ||
                'Error al actualizar el registro, por favor intente de nuevo',
              type: MessageType.Danger,
            });
            return EMPTY;
          })
        );
      })
    )
  );

  removeService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ServicesActions.removeService),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._servicesService.remove(action.id).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((res) => {
            this._layoutUtilsServices.showNotification({
              message: 'Se ha eliminado el registro exitosamente',
              type: MessageType.Susses,
            });
            this.store.dispatch(
              ServicesActions.loadServices({ filters: this.filters })
            );
            return ServicesActions.removeServiceSuccess({ id: action.id });
          }),
          catchError(({ error }) => {
            this._layoutUtilsServices.showNotification({
              message:
                error?.message ||
                'Error al eliminar el registro, por favor intente de nuevo',
              type: MessageType.Danger,
            });
            return EMPTY;
          })
        );
      })
    )
  );

  loadDurations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ServicesActions.loadServiceDurations),
      exhaustMap((action) => {
        return this._servicesService.findDurationsAll().pipe(
          map((res) =>
            ServicesActions.loadServiceDurationsSuccess({ items: res.data })
          ),
          catchError(() => EMPTY)
        );
      })
    )
  );

  loadSalesPersons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ServicesActions.loadServiceSalesPersons),
      exhaustMap((action) => {
        console.log('dsdsd');
        return this._servicesService.findSalesPersonsAll().pipe(
          map((res) =>
            ServicesActions.loadServiceSalesPersonsSuccess({ items: res })
          ),
          catchError(() => EMPTY)
        );
      })
    )
  );

  loadAttributes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ServicesActions.loadServiceAttributes),
      exhaustMap((action) => {
        return this._valuesService.findAll({ table_code: 'ACCESSORIES' }).pipe(
          map((res) =>
            ServicesActions.loadServiceAttributesSuccess({ items: res })
          ),
          catchError(() => EMPTY)
        );
      })
    )
  );

  filters: any = {};
  constructor(
    private actions$: Actions,
    private _servicesService: ServicesService,
    private _valuesService: ValuesService,
    private _layoutUtilsServices: LayoutUtilsServices,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.store
      .pipe(select(currentServicesFilters))
      .subscribe((data) => (this.filters = data));
  }
}
