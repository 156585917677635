import { Injectable } from '@angular/core';
import { HttpMethodsService } from './http-methods.services';
import { SaleModel, VehicleRequestModel } from '../_models/sale.model';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SalesService {
  constructor(private _httpService: HttpMethodsService) {}

  create(data: SaleModel) {
    return this._httpService.post('sales', data);
  }


  findOne(id: string) : Observable<SaleModel> {
    return this._httpService.get('sale', { id })
  }

  // findOne(id: string) {
  //   return this._httpService.get('sale', { id }).pipe(
  //     map((res: any) => {
  //       const data = new SaleModel(res);
  //       data.customer.document_type_id = res?.customer?.attributes?.find(
  //         (att: any) => att.table_code === 'DOCUMENT_TYPE'
  //       )?.value?.id;

  //       data.customer.address.ubigeo_id = res?.customer?.address?.ubigeo?.id;

  //       data.delivery.ubigeo_id = res?.delivery?.ubigeo?.id;
  //       data.payment.invoice_type_id = res?.payment?.invoice_type?.id;
  //       data.service.service_id  = res?.payment?.payment_method?.id;

  //       console.log(data)
  //       return data;
  //     })
  //   );
  // }

  findAll(filters: any = {}) {
    return this._httpService.get('sales', {}, filters).pipe(
      map((res: any) => {
        return {
          data: res?.data.map((r: any) => new SaleModel(r)),
          total: res?.total,
        };
      })
    );
  }

  remove(id: string) {
    return this._httpService.delete('sale', { id });
  }

  update(id: string, data: VehicleRequestModel) {
    return this._httpService.patch('sale', data, { id });
  }
}
