import { createReducer, on } from '@ngrx/store';
import { DealersActions } from '../_actions/dealers.actions';
import { DealerModel } from '../../_models/dealer.model';

export interface DealersState {
  list: {
    filters: any;
    data: ReadonlyArray<DealerModel>;
    total: number;
  };
  selected: DealerModel | null;
}

export const initialStateDealer: DealersState = {
  list: {
    filters: {},
    data: [],
    total: 0,
  },
  selected: null,
};

export const dealersFeatureKey = 'dealers';

export const dealersReducer = createReducer(
  initialStateDealer,
  on(DealersActions.loadDealers, (state, { filters }) => ({
    ...state,
    list: {
      ...state.list,
      filters,
    },
  })),
  on(DealersActions.loadDealersSuccess, (state, { items, total }) => ({
    ...state,
    list: {
      ...state.list,
      data: items,
      total: total,
    },
  })),
  on(DealersActions.loadDealerSuccess, (state, { data }) => ({
    ...state,
    selected: data,
  }))
);
