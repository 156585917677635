import { Injectable } from "@angular/core";
import { HttpMethodsService } from "./http-methods.services";
import { map } from "rxjs";
import { PersonRequestModel } from "../_models/person.model";
import { UserModel, UserSalesmanRequestModel } from "../_models/user.model";

@Injectable({
    providedIn: 'root',
})

export class SalesmenService {
    constructor(private _httpService: HttpMethodsService) { }

    findOne(id: string) {
        return this._httpService.get(`salesman`, { id });
    }
    findAll(filters: any = {}) {
        return this._httpService.get('salesmen', {}, filters).pipe(
            map((res: any) => {
                return {
                    data: res?.data.map((r: any) => (new UserModel(r))),
                    total: res?.total
                }
            }));;
    }
    //   create(data: UserModel) {
    //     return this._httpService.post('users', data);
    //   }

      update(id: string, data: PersonRequestModel) {
        return this._httpService.patch('salesman', data, { id });
      }

      remove(id: string) {
        return this._httpService.delete('salesman', { id });
      }

}
