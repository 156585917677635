import { Injectable } from '@angular/core';
import { BrandModel, ModelBrandModel } from '../_models/brand.model';
import { HttpMethodsService } from './http-methods.services';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BrandsService {
  constructor(private _httpService: HttpMethodsService) {}

  findOne(brand_id: string): Observable<BrandModel> {
    return this._httpService
      .get(`brand`, { brand_id })
      .pipe(map((res: any) => new BrandModel(res)));
  }
  findAll(filters: any = {}) {
    return this._httpService.get('brands', {}, filters).pipe(
      map((res: any) => {
        return {
          data: res?.data.map((r: any) => new BrandModel(r)),
          total: res?.total,
        };
      })
    );
  }
  create(data: BrandModel) {
    return this._httpService.post('brands', data);
  }

  update(brand_id: string, data: BrandModel) {
    return this._httpService.patch('brand', data, { brand_id });
  }

  remove(brand_id: string) {
    return this._httpService.delete('brand', { brand_id });
  }

  findOneModel(
    brand_id: string,
    model_id: string
  ): Observable<ModelBrandModel> {
    return this._httpService
      .get(`brand_model`, { brand_id, model_id })
      .pipe(map((res: any) => new ModelBrandModel(res)));
  }

  createModel(brand_id: string, data: ModelBrandModel) {
    return this._httpService.post('brand_models', data, { brand_id });
  }

  updateModel(brand_id: string, model_id: string, data: ModelBrandModel) {
    return this._httpService.patch('brand_model', data, { brand_id, model_id });
  }

  removeModel(brand_id: string, model_id: string) {
    return this._httpService.delete('brand_model', { brand_id, model_id });
  }
}
