import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, exhaustMap, catchError, finalize } from 'rxjs/operators';
import { DealersService } from '../../_services/dealers.service';
import { DealersActions } from '../_actions/dealers.actions';
import { LayoutActions } from '../_actions/layout.actions';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { AppState } from '../index';
import {
  LayoutUtilsServices,
  MessageType,
} from '../../_services/layout-utils.services';
import { currentDealersFilters } from '../_selectors/dealers.selectors';

@Injectable()
export class DealersEffects {
  loadDealers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DealersActions.loadDealers),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._dealersService.findAll(action.filters).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((res) =>
            DealersActions.loadDealersSuccess({
              items: res.data,
              total: res.total,
            })
          ),
          catchError(() => EMPTY)
        );
      })
    )
  );

  loadDealer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DealersActions.loadDealer),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._dealersService.findOne(action.id).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((data) => DealersActions.loadDealerSuccess({ data })),
          catchError(() => EMPTY)
        );
      })
    )
  );

  addDealer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DealersActions.addDealer),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._dealersService.create(action.data).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((res) => {
            this._layoutUtilsServices.showNotification({
              message: 'Se ha creado el registro exitosamente',
              type: MessageType.Susses,
            });
            this.router.navigate(['/dealers', res.id, 'edit']);
            return DealersActions.addDealerSuccess({ data: res });
          }),
          catchError(() => {
            this._layoutUtilsServices.showNotification({
              message: 'Error al crear el registro, por favor intente de nuevo',
              type: MessageType.Danger,
            });
            return EMPTY;
          })
        );
      })
    )
  );

  editDealer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DealersActions.editDealer),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._dealersService.update(action.id, action.data).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((res) => {
            this._layoutUtilsServices.showNotification({
              message: 'Se ha actualizado el registro exitosamente',
              type: MessageType.Susses,
            });
            this.router.navigate(['/dealers']);
            return DealersActions.editDealerSuccess({ id: action.id, data: res });
          }),
          catchError(() => {
            this._layoutUtilsServices.showNotification({
              message:
                'Error al actualizar el registro, por favor intente de nuevo',
              type: MessageType.Danger,
            });
            return EMPTY;
          })
        );
      })
    )
  );

  removeDealer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DealersActions.removeDealer),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._dealersService.remove(action.id).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((res) => {
            this._layoutUtilsServices.showNotification({
              message: 'Se ha eliminado el registro exitosamente',
              type: MessageType.Susses,
            });
            this.store.dispatch(
              DealersActions.loadDealers({ filters: this.filters })
            );
            return DealersActions.removeDealerSuccess({ id: action.id });
          }),
          catchError(() => {
            this._layoutUtilsServices.showNotification({
              message:
                'Error al eliminar el registro, por favor intente de nuevo',
              type: MessageType.Danger,
            });
            return EMPTY;
          })
        );
      })
    )
  );

  filters: any = {};
  constructor(
    private actions$: Actions,
    private _dealersService: DealersService,
    private _layoutUtilsServices: LayoutUtilsServices,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.store
      .pipe(select(currentDealersFilters))
      .subscribe((data) => (this.filters = data));
  }
}
