import { createReducer, on } from '@ngrx/store';
import { AuthActions } from '../_actions/auth.actions';
import { UserModel } from '../../_models/user.model';
import { SalesmanActions } from '../_actions/salesman.actions';

export interface SalesmenState {
    list: {
        filters: any;
        data: ReadonlyArray<UserModel>;
        total: number;
    };
    selected: UserModel | null;
}

export const initialStateSalesman: SalesmenState = {
    list: {
        filters: {},
        data: [],
        total: 0,
    },
    selected: null,
};

export const salesmenFeatureKey = 'salesmen';

export const salesmenReducer = createReducer(
    initialStateSalesman,
    on(SalesmanActions.loadSalesmen, (state, { filters }) => ({
        ...state,
        list: {
            ...state.list,
            filters,
        },
    })),

    on(SalesmanActions.loadSalesmenSuccess, (state, { items, total }) => ({
        ...state,
        list: {
            ...state.list,
            data: items,
            total: total,
        },
    })),

    on(SalesmanActions.loadSalesmanSuccess, (state, { data }) => ({
        ...state,
        selected: data,
    })),
);
