import { createReducer, on } from '@ngrx/store';
import { LayoutActions } from '../_actions/layout.actions';
import { SalesActions } from '../_actions/sales.actions';
import { SaleModel } from '../../_models/sale.model';


export interface SalesState {
    list: {
        filters: any;
        data: ReadonlyArray<SaleModel>;
        total: number;
    };
    selected: SaleModel | null;

}

export const initialStateSales: SalesState = {
    list: {
        filters: {},
        data: [],
        total: 0,
    },
    selected: null,

};

export const SalesFeatureKey = 'Sales';

export const salesReducer = createReducer(
    initialStateSales,
    on(SalesActions.loadSales, (state, { filters }) => ({
        ...state,
        list: {
            ...state.list,
            filters,
        },
    })),
    on(SalesActions.loadSalesSuccess, (state, { items, total }) => ({
        ...state,
        list: {
            ...state.list,
            data: items,
            total: total,
        },
    })),
    on(SalesActions.loadSaleSuccess, (state, { data }) => ({
        ...state,
        selected: data,
    }))

);
