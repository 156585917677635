import { createReducer, on } from '@ngrx/store';
import { PrizesActions } from '../_actions/prizes.actions';
import { PrizeModel } from '../../_models/prize.model';

export interface PrizesState {
  list: {
    filters: any;
    data: ReadonlyArray<PrizeModel>;
    total: number;
  };
  selected: PrizeModel | null;
}

export const initialStatePrize: PrizesState = {
  list: {
    filters: {},
    data: [],
    total: 0,
  },
  selected: null,
};

export const prizesFeatureKey = 'prizes';

export const prizesReducer = createReducer(
  initialStatePrize,
  on(PrizesActions.loadPrizes, (state, { filters }) => ({
    ...state,
    list: {
      ...state.list,
      filters,
    },
  })),
  on(PrizesActions.loadPrizesSuccess, (state, { items, total }) => ({
    ...state,
    list: {
      ...state.list,
      data: items,
      total: total,
    },
  })),
  on(PrizesActions.loadPrizeSuccess, (state, { data }) => ({
    ...state,
    selected: data,
  }))
);
