import { Injectable } from "@angular/core";
import { HttpMethodsService } from "./http-methods.services";
import { map } from "rxjs";
import { PersonRequestModel } from "../_models/person.model";
import { UserModel, UserSalesmanRequestModel } from "../_models/user.model";

import { HttpClient, HttpHeaders } from '@angular/common/http';
import saveAs from 'file-saver';
import { UrlService } from './url.services';
import { HttpUtilsService } from './http-utils.service';

@Injectable({
    providedIn: 'root',
})

export class SalesmenService {
    constructor(
        private http: HttpClient,
        private _httpService: HttpMethodsService,
        private httpUtils: HttpUtilsService,
        private routing: UrlService
    ) { }

    findOne(id: string) {
        return this._httpService.get(`salesman`, { id });
    }
    findAll(filters: any = {}) {
        return this._httpService.get('salesmen', {}, filters).pipe(
            map((res: any) => {
                return {
                    data: res?.data.map((r: any) => (new UserModel(r))),
                    total: res?.total
                }
            }));;
    }
    //   create(data: UserModel) {
    //     return this._httpService.post('users', data);
    //   }

      update(id: string, data: PersonRequestModel) {
        return this._httpService.patch('salesman', data, { id });
      }

      remove(id: string) {
        return this._httpService.delete('salesman', { id });
      }

      export(filters: {}, file_name: string) {
        let httpHeaders = this.httpUtils.getHTTPHeaders();
        const httpParams = this.httpUtils.getFindHTTPParams({ filter: filters });
        var route = this.routing.getUrl(`salesmen_export`);
        return this.http.get(`${route}`, {
            observe: 'response',
            responseType: 'blob',
            headers: httpHeaders,
            params: httpParams
        }).pipe((map((res) => {
            const blob = res.body || new Blob();
            saveAs(blob, `${file_name}-${Date.now()}.xlsx`)
            return res
        }))
        )
      }

}
