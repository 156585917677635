import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, exhaustMap, catchError, finalize } from 'rxjs/operators';
import { LayoutActions } from '../_actions/layout.actions';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { AppState } from '../index';
import {
    LayoutUtilsServices,
    MessageType,
} from '../../_services/layout-utils.services';

import { RankingActions } from '../_actions/ranking.actions';

import { RankingService } from '../../_services/ranking.service';
import { currentRankingFilters } from '../_selectors/ranking.selectors';

@Injectable()
export class RankingEffects {
    loadRanking$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RankingActions.loadRanking),
            exhaustMap((action) => {
                this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
                return this._rankingService.find(action.filters).pipe(
                    finalize(() =>
                        this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
                    ),
                    map((res) =>
                        RankingActions.loadRankingSuccess({
                            items: res.data,
                            total: res.total,
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        )
    );




    filters: any = {};
    constructor(
        private actions$: Actions,
        private _rankingService: RankingService,
        private _layoutUtilsServices: LayoutUtilsServices,
        private store: Store<AppState>,
        private router: Router
    ) {
        this.store
            .pipe(select(currentRankingFilters))
            .subscribe((data) => (this.filters = data));
    }
}
