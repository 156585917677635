import { createReducer, on } from '@ngrx/store';
import { BrandsActions } from '../_actions/brands.actions';
import { BrandModel, ModelBrandModel } from '../../_models/brand.model';

export interface BrandsState {
  list: {
    filters: any;
    data: ReadonlyArray<BrandModel>;
    total: number;
  };
  selected: BrandModel | null;
  modelSelected: ModelBrandModel | null;
}

export const initialStateBrand: BrandsState = {
  list: {
    filters: {},
    data: [],
    total: 0,
  },
  selected: null,
  modelSelected: null,
};

export const brandsFeatureKey = 'brands';

export const brandsReducer = createReducer(
  initialStateBrand,
  on(BrandsActions.loadBrands, (state, { filters }) => ({
    ...state,
    list: {
      ...state.list,
      filters,
    },
  })),
  on(BrandsActions.loadBrandsSuccess, (state, { items, total }) => ({
    ...state,
    list: {
      ...state.list,
      data: items,
      total: total,
    },
  })),
  on(BrandsActions.loadBrandSuccess, (state, { data }) => ({
    ...state,
    selected: data,
  })),
  on(BrandsActions.loadBrandModelSuccess, (state, { data }) => ({
    ...state,
    modelSelected: data,
  })),
  on(BrandsActions.removeBrandModelSuccess, (state, { id }) => ({
    ...state,
    selected: state.selected
      ? {
          ...state.selected,
          models: state.selected.models.filter((x) => x.id !== id),
        }
      : null,
  }))
);
