import { Inject, Injectable } from '@angular/core';
import { UserModel, UserRequestModel } from '../_models/user.model';
import { HttpMethodsService } from './http-methods.services';
import { map } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private _httpService: HttpMethodsService) {}

  findOne(user_id: string) {
    return this._httpService.get(`user`, { user_id });
  }
  findAll(filters: any = {}) {
    return this._httpService.get('users', {}, filters).pipe(
      map((res: any) => {
        return {
          data: res?.data.map((r: any) => (new UserModel(r))),
          total: res?.total
        }
      }));;
  }
  create(data: UserRequestModel) {
    return this._httpService.post('users', data);
  }

  update(user_id: string, data: UserRequestModel) {
    return this._httpService.patch('user', data, { user_id });
  }

  remove(user_id: string) {
    return this._httpService.delete('user', { user_id });
  }

  findTabsAll(filters: any = {}) {
    return this._httpService.get('tabs', {}, filters);
  }
}
