import { createReducer, on } from '@ngrx/store';
import { ValuesActions } from '../_actions/values.actions';
import { UserModel } from '../../_models/user.model';
import { ValueModel } from '../../_models/base.model';

export interface ValuesState {
  typesDocuments: ValueModel[];
  ubigeos: ValueModel[];
  brands: ValueModel[];
  salesChannel: ValueModel[];
  insuranceCompanies: ValueModel[];
  invoiceType: ValueModel[];
  employeeType: ValueModel[];
}

export const initialStateVehicle: ValuesState = {
  typesDocuments: [],
  ubigeos: [],
  brands: [],
  salesChannel: [],
  insuranceCompanies: [],
  invoiceType: [],
  employeeType: []
};

export const valuesFeatureKey = 'values';

export const valuesReducer = createReducer(
  initialStateVehicle,
  on(ValuesActions.loadValuesTypesDocumentsSuccess, (state, { items }) => ({
    ...state,
    typesDocuments: items.map((item) => new ValueModel(item)),
  })),
  on(ValuesActions.loadValuesUbigeosSuccess, (state, { items }) => ({
    ...state,
    ubigeos: items.map((item) => new ValueModel(item)),
  })),
  on(ValuesActions.loadValuesBrandsSuccess, (state, { items }) => ({
    ...state,
    brands: items.map((item) => new ValueModel(item)),
  })),
  on(ValuesActions.loadValuesSalesChannelSuccess, (state, { items }) => ({
    ...state,
    salesChannel: items.map((item) => new ValueModel(item)),
  })),
  on(ValuesActions.loadValuesInsuranceCompaniesSuccess, (state, { items }) => ({
    ...state,
    insuranceCompanies: items.map((item) => new ValueModel(item)),
  })),
  on(ValuesActions.loadValuesInvoiceTypeSuccess, (state, { items }) => ({
    ...state,
    invoiceType: items.map((item) => new ValueModel(item)),
  })),
  on(ValuesActions.loadValuesEmployeeTypeSuccess, (state, { items }) => ({
    ...state,
    employeeType: items.map((item) => new ValueModel(item)),
  }))
);
