import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AppState } from '../_state';
import { currentAuthToken } from '../_state/_selectors/auth.selectors';
import { of } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';

export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const store = inject(Store<AppState>);

  return store.pipe(select(currentAuthToken)).pipe(
    map((token) => {
      if (!token) {
        router.navigate(['/auth/login'], {
          queryParams: { returnUrl: state.url },
        });
        return false;
      } else {
        return true;
      }
    }),
    catchError(() => of(router.createUrlTree(['/auth/login'])))
  );
};
