import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, exhaustMap, catchError, finalize } from 'rxjs/operators';
import { SalesService } from '../../_services/sales.service';
import { SalesActions } from '../_actions/sales.actions';
import { LayoutActions } from '../_actions/layout.actions';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { AppState } from '../index';
import {
  LayoutUtilsServices,
  MessageType,
} from '../../_services/layout-utils.services';
import { currentSalesFilters } from '../_selectors/sales.selectors';

@Injectable()
export class SalesEffects {
  loadSales$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesActions.loadSales),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._salesService.findAll(action.filters).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((res) => {
            return SalesActions.loadSalesSuccess({
              items: res.data,
              total: res.total,
            });
          }),
          catchError(() => EMPTY)
        );
      })
    )
  );

  loadSale$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesActions.loadSale),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._salesService.findOne(action.id).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((data) => SalesActions.loadSaleSuccess({ data })),
          catchError(() => EMPTY)
        );
      })
    )
  );

  //   addSale$ = createEffect(() =>
  //     this.actions$.pipe(
  //       ofType(SalesActions.addSale),
  //       exhaustMap((action) => {
  //         this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
  //         return this._salesService.create(action.data).pipe(
  //           finalize(() =>
  //             this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
  //           ),
  //           map((res) => {
  //             this._layoutUtilsServices.showNotification({
  //               message: 'Se ha creado el registro exitosamente',
  //               type: MessageType.Susses,
  //             });
  //             this.router.navigate(['/sales']);
  //             return SalesActions.addSaleSuccess({ data: res });
  //           }),
  //           catchError(() => {
  //             this._layoutUtilsServices.showNotification({
  //               message: 'Error al crear el registro, por favor intente de nuevo',
  //               type: MessageType.Danger,
  //             });
  //             return EMPTY;
  //           })
  //         );
  //       })
  //     )
  //   );

  editSale$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesActions.editSale),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        console.log(action.data);
        return this._salesService.update(action.id, action.data).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((res) => {
            this._layoutUtilsServices.showNotification({
              message: 'Se ha actualizado el registro exitosamente',
              type: MessageType.Susses,
            });
            return SalesActions.editSaleSuccess({ id: action.id, data: res });
          }),
          catchError(() => {
            this._layoutUtilsServices.showNotification({
              message:
                'Error al actualizar el registro, por favor intente de nuevo',
              type: MessageType.Danger,
            });
            return EMPTY;
          })
        );
      })
    )
  );

  removeSale$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesActions.removeSale),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._salesService.remove(action.id).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((res) => {
            this._layoutUtilsServices.showNotification({
              message: 'Se ha eliminado el registro exitosamente',
              type: MessageType.Susses,
            });
            this.store.dispatch(
              SalesActions.loadSales({ filters: this.filters })
            );
            return SalesActions.removeSaleSuccess({ id: action.id });
          }),
          catchError(() => {
            this._layoutUtilsServices.showNotification({
              message:
                'Error al eliminar el registro, por favor intente de nuevo',
              type: MessageType.Danger,
            });
            return EMPTY;
          })
        );
      })
    )
  );

  filters: any = {};
  constructor(
    private actions$: Actions,
    private _salesService: SalesService,
    private _layoutUtilsServices: LayoutUtilsServices,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.store
      .pipe(select(currentSalesFilters))
      .subscribe((data) => (this.filters = data));
  }
}
