import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, exhaustMap, catchError, finalize } from 'rxjs/operators';
import { BrandsService } from '../../_services/brands.service';
import { BrandsActions } from '../_actions/brands.actions';
import { LayoutActions } from '../_actions/layout.actions';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { AppState } from '../index';
import {
  LayoutUtilsServices,
  MessageType,
} from '../../_services/layout-utils.services';
import { currentBrandsFilters } from '../_selectors/brands.selectors';

@Injectable()
export class BrandsEffects {
  loadBrands$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BrandsActions.loadBrands),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._brandsService.findAll(action.filters).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((res) =>
            BrandsActions.loadBrandsSuccess({
              items: res.data,
              total: res.total,
            })
          ),
          catchError(() => EMPTY)
        );
      })
    )
  );

  loadBrand$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BrandsActions.loadBrand),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._brandsService.findOne(action.id).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((data) => BrandsActions.loadBrandSuccess({ data })),
          catchError(() => EMPTY)
        );
      })
    )
  );

  addBrand$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BrandsActions.addBrand),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._brandsService.create(action.data).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((res) => {
            this._layoutUtilsServices.showNotification({
              message: 'Se ha creado el registro exitosamente',
              type: MessageType.Susses,
            });
            this.router.navigate(['/brands']);
            return BrandsActions.addBrandSuccess({ data: res });
          }),
          catchError(() => {
            this._layoutUtilsServices.showNotification({
              message: 'Error al crear el registro, por favor intente de nuevo',
              type: MessageType.Danger,
            });
            return EMPTY;
          })
        );
      })
    )
  );

  editBrand$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BrandsActions.editBrand),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._brandsService.update(action.id, action.data).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((res) => {
            this._layoutUtilsServices.showNotification({
              message: 'Se ha actualizado el registro exitosamente',
              type: MessageType.Susses,
            });
            this.router.navigate(['/brands']);
            return BrandsActions.editBrandSuccess({ id: action.id, data: res });
          }),
          catchError(() => {
            this._layoutUtilsServices.showNotification({
              message:
                'Error al actualizar el registro, por favor intente de nuevo',
              type: MessageType.Danger,
            });
            return EMPTY;
          })
        );
      })
    )
  );

  removeBrand$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BrandsActions.removeBrand),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._brandsService.remove(action.id).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((res) => {
            this._layoutUtilsServices.showNotification({
              message: 'Se ha eliminado el registro exitosamente',
              type: MessageType.Susses,
            });
            this.store.dispatch(
              BrandsActions.loadBrands({ filters: this.filters })
            );
            return BrandsActions.removeBrandSuccess({ id: action.id });
          }),
          catchError(() => {
            this._layoutUtilsServices.showNotification({
              message:
                'Error al eliminar el registro, por favor intente de nuevo',
              type: MessageType.Danger,
            });
            return EMPTY;
          })
        );
      })
    )
  );

  loadBrandModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BrandsActions.loadBrandModel),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._brandsService
          .findOneModel(action.brand_id, action.id)
          .pipe(
            finalize(() =>
              this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
            ),
            map((data) => BrandsActions.loadBrandModelSuccess({ data })),
            catchError(() => EMPTY)
          );
      })
    )
  );

  addBrandModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BrandsActions.addBrandModel),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._brandsService
          .createModel(action.brand_id, action.data)
          .pipe(
            finalize(() =>
              this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
            ),
            map((res) => {
              this._layoutUtilsServices.showNotification({
                message: 'Se ha creado el registro exitosamente',
                type: MessageType.Susses,
              });
              this.router.navigate(['/brands', action.brand_id, 'edit']);
              return BrandsActions.addBrandModelSuccess({ data: res });
            }),
            catchError(() => {
              this._layoutUtilsServices.showNotification({
                message:
                  'Error al crear el registro, por favor intente de nuevo',
                type: MessageType.Danger,
              });
              return EMPTY;
            })
          );
      })
    )
  );

  editBrandModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BrandsActions.editBrandModel),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._brandsService
          .updateModel(action.brand_id, action.id, action.data)
          .pipe(
            finalize(() =>
              this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
            ),
            map((res) => {
              this._layoutUtilsServices.showNotification({
                message: 'Se ha actualizado el registro exitosamente',
                type: MessageType.Susses,
              });
              this.router.navigate(['/brands', action.brand_id, 'edit']);
              return BrandsActions.editBrandModelSuccess({
                id: action.id,
                data: res,
              });
            }),
            catchError(() => {
              this._layoutUtilsServices.showNotification({
                message:
                  'Error al actualizar el registro, por favor intente de nuevo',
                type: MessageType.Danger,
              });
              return EMPTY;
            })
          );
      })
    )
  );

  removeBrandModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BrandsActions.removeBrandModel),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._brandsService.removeModel(action.brand_id, action.id).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((res) => {
            this._layoutUtilsServices.showNotification({
              message: 'Se ha eliminado el registro exitosamente',
              type: MessageType.Susses,
            });
            return BrandsActions.removeBrandModelSuccess({ id: action.id });
          }),
          catchError(() => {
            this._layoutUtilsServices.showNotification({
              message:
                'Error al eliminar el registro, por favor intente de nuevo',
              type: MessageType.Danger,
            });
            return EMPTY;
          })
        );
      })
    )
  );

  filters: any = {};
  constructor(
    private actions$: Actions,
    private _brandsService: BrandsService,
    private _layoutUtilsServices: LayoutUtilsServices,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.store
      .pipe(select(currentBrandsFilters))
      .subscribe((data) => (this.filters = data));
  }
}
